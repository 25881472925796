<template>
  <div class="container px-5 mx-auto md:max-w-5xl lg:max-w-7xl">
    <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
      <div
        class="flex flex-col items-center justify-center order-2 my-5 space-y-6 md:order-1 md:space-y-10 md:items-baseline md:my-0"
      >
        <img src="@/assets/login-obj.png" class="max-w-full" />
      </div>
      <div
        class="flex flex-col items-center justify-center order-1 my-5 space-y-6 md:order-2 md:space-y-8 md:my-0"
      >
      <router-link to="/" class="font-bold py-4 router-link-active router-link-exact-active text-4xl lg:text-5xl text-gray-900"
      > <SiteLogo></SiteLogo> </router-link>
        <h3 class="mt-5 text-3xl font-bold uppercase md:text-3xl">
            Join the wait list        </h3>
        <div class="w-20 h-2 bg-gray-900"></div>
        <div class="flex w-full">
          <div class="flex flex-col flex-1">
            <WaitingListForm />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WaitingListForm from '../components/auth/WaitingListForm.vue'
import SiteLogo from '@/components/SiteLogo'
export default {
  components: {
    WaitingListForm,
    SiteLogo
  },
  setup () {
    return {}
  }
}
</script>
