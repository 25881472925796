<template>
  <form @submit.prevent="onSubmit" novalidate  v-if="!success">
    <TextInput
      type="text"
      class="mb-8"
      placeholder=" First Name"
      v-model="v$.first_name.$model"
      :icon="faUser"
      :errors="v$?.first_name?.$errors[0]?.$message"
    />
    <TextInput
      type="text"
      class="mb-8"
      placeholder=" Last Name"
      v-model="v$.last_name.$model"
      :icon="faUser"
      :errors="v$?.last_name?.$errors[0]?.$message"
    />
    <TextInput
      type="email"
      class="mb-8"
      placeholder=" E-mail"
      v-model="v$.email.$model"
      :icon="faEnvelope"
      :errors="v$?.email?.$errors[0]?.$message"
    />
    <fieldset class="mt-4">
      <legend class="">Are you interested in becoming a tasker/service provider?</legend>
      <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
        <div class="flex items-center">
          <input
            id="is_taker_yes"
            name="is_taker"
            type="radio"
            v-model="v$.is_tasker.$model"
            :value="true"
            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
          />
          <label for="is_taker_yes" class="ml-3 block text-sm font-medium text-gray-700">
            Yes
          </label>
        </div>
        <div class="flex items-center">
          <input
            id="is_taker_no"
            name="is_taker"
            type="radio"
            v-model="v$.is_tasker.$model"
            :value="false"
            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
          />
          <label for="is_taker_no" class="ml-3 block text-sm font-medium text-gray-700">
            No
          </label>
        </div>
      </div>
    </fieldset>

    <fieldset class="mt-4">
      <legend class="">Are you interested in volunteering?</legend>
      <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
        <div class="flex items-center">
          <input
            id="is_volunteer_yes"
            name="is_volunteer"
            v-model="v$.is_volunteer.$model"
            type="radio"
            :value="true"
            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
          />
          <label
            for="is_volunteer_yes"
            class="ml-3 block text-sm font-medium text-gray-700"
          >
            Yes
          </label>
        </div>
        <div class="flex items-center">
          <input
            id="is_volunteer_no"
            name="is_volunteer"
            type="radio"
            v-model="v$.is_volunteer.$model"
            :value="false"
            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
          />
          <label
            for="is_volunteer_no"
            class="ml-3 block text-sm font-medium text-gray-700"
          >
            No
          </label>
        </div>
      </div>
    </fieldset>
    <SubmitButton class="w-full my-5" :isLoading="isLoading"
      >Join the wait list</SubmitButton
    >
    <div class="relative w-full">
      <div
        v-if="errors"
        class="absolute w-full p-4 font-medium text-red-800 rounded-md bg-red-50"
      >
        {{ errors }}
      </div>
    </div>
  </form>

  <div class="rounded-md bg-blue-50 p-4" v-if="success">
    <div class="flex">
      <div class="flex-shrink-0">
        <FontAwesomeIcon class="text-xl text-custom-green" :icon="faCheckCircle" />
      </div>
      <div class="ml-3 flex-1 md:flex md:justify-between items-center">
        <p class="text-sm text-green-700">{{success}}</p>
        <p class="mt-3  text-sm md:mt-0 md:ml-6">
          <router-link class="text-custom-green-dark hover:bg-custom-green-dark py-2 px-4 rounded hover:text-white" to="/">OK</router-link>
        </p>
      </div>
    </div>
  </div>

</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, email } from '@vuelidate/validators'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faEnvelope, faUser, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import TextInput from '../TextInput.vue'
import SubmitButton from '../SubmitButton.vue'
import { reactive, ref } from '@vue/reactivity'
import axios from 'axios'

export default {
  components: { TextInput, SubmitButton, FontAwesomeIcon },
  setup () {
    const isLoading = ref(false)
    const errors = ref('')
    const success = ref('')

    const state = reactive({
      email: '',
      first_name: '',
      last_name: '',
      is_volunteer: false,
      is_tasker: false
    })
    const rules = {
      first_name: {
        required: helpers.withMessage('First name is required.', required)
      },
      last_name: {
        required: helpers.withMessage('Last name is required.', required)
      },

      is_tasker: {
        required: helpers.withMessage('Verify you are a service provider or not', required)
      },
      is_volunteer: {
        required: helpers.withMessage('Are you Volunteer.', required)
      },
      email: {
        required: helpers.withMessage('Email field is required', required),
        email: helpers.withMessage('Email should be a valid email address ', email)
      }
    }
    const v$ = useVuelidate(rules, state)

    const onSubmit = async () => {
      v$.value.$touch()
      if (v$.value.$invalid) return
      isLoading.value = true
      errors.value = null
      success.value = null
      await axios.post(
        'https://blooming-mountain-11200.herokuapp.com/api/join-wait-list',
        {
          first_name: state.first_name,
          last_name: state.last_name,
          email: state.email,
          tasker: state.is_tasker,
          volunteer: state.is_volunteer
        }
      )
        .then(res => {
          if (res.status === 200) { success.value = 'your email has been send.' }
        })
        .catch(err => {
          console.log(err)
          alert('Something went wrong! please try again')
        })
        .then(() => {
          isLoading.value = false
        })
      console.log('form submitted', state)
    }
    return {
      onSubmit,
      v$,
      faEnvelope,
      faUser,
      errors,
      faCheckCircle,
      isLoading,
      success
    }
  }
}
</script>
